var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"permanent":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"d-flex justify-space-between pb-0 pr-2",staticStyle:{"padding":"14px"}},[(!_vm.mini)?_c('v-text-field',{staticClass:"mr-2 fixed-size",attrs:{"label":_vm.$t('system.search'),"dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[(_vm.mini)?_c('v-icon',{attrs:{"size":"18"}},[_vm._v("fas fa-chevron-right ")]):_c('v-icon',{attrs:{"size":"18"}},[_vm._v("fas fa-chevron-left ")])],1)],1),_c('div',{staticClass:"d-flex justify-space-between px-2 mb-2",class:{'mt-7': _vm.mini}},[(!_vm.mini)?_c('h5',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.$t('system.calendars'))+" ")]):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-top":"-5px"},attrs:{"icon":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("fas fa-plus")])],1)]}}]),model:{value:(_vm.createMenu),callback:function ($$v) {_vm.createMenu=$$v},expression:"createMenu"}},[_c('CreateEditCalendar',{on:{"close":function($event){_vm.createMenu = false},"create":_vm.refreshCalendars}})],1)],1),_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.filterCalendars()),function(item){return _c('v-list-item',{key:item.id},[_c('div',{staticClass:"w-100 d-flex justify-space-between"},[_c('v-checkbox',{staticClass:"mt-0",class:{'pt-2': _vm.mini},attrs:{"color":item['calendar_calendars.color']},on:{"change":function($event){return _vm.selectCalendars()}},scopedSlots:_vm._u([(!_vm.mini)?{key:"label",fn:function(){return [_c('span',{staticClass:"checkbox-label",class:_vm.defaultCalendar === item['calendar_calendars.id'] ? 'font-weight-bold' : 'font-weight-normal'},[_vm._v(" "+_vm._s(item['calendar_calendars.name'])+" ")])]},proxy:true}:null],null,true),model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}}),(!_vm.mini)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"relative","right":"-8px"},attrs:{"disabled":_vm.loading,"loading":_vm.loading,"icon":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setAsDefault(item['calendar_calendars.id'])}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('system.set_as_default'))+" ")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){item.menu = !item.menu}}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(_vm.$t('system.edit'))+" ")])]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('CreateEditCalendar',{attrs:{"item":item},on:{"close":function($event){item.menu = false},"edit":function($event){_vm.refreshCalendars(); item.menu = false}}})],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_c('span',{staticStyle:{"color":"#ba0000"}},[_vm._v(_vm._s(_vm.$t('system.delete')))])])]}}],null,true)},[_c('v-card',{staticClass:"px-3 py-2"},[_vm._v(" "+_vm._s(_vm.$t('system.are_you_sure'))+"? "),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"red red-darken-2","dark":"","small":""},on:{"click":function($event){return _vm.deleteEvent(item['calendar_calendars.id'])}}},[_vm._v(_vm._s(_vm.$t('system.yes'))+" ")])],1)],1)],1)],1)],1):_vm._e()],1)])}),(!_vm.calendars.length > 0)?_c('v-list-item',[_c('i',[_vm._v(_vm._s(_vm.$t('system.no_data')))])]):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }