<template>
  <div>
    <v-skeleton-loader
        :loading="loading && !formLoaded"
        class="mx-auto"
        type="table"
    >
      <div class="d-flex">
        <CalendarSidebar
            :calendars="calendars"
            :default-calendar="defaultCalendar"
            @refreshCalendars="fetchCalendars(); getEventsTrigger(true)"
            @selectCalendars="selectCalendars"
            @setAsDefault="setAsDefault"
        />
        <div style="width: 100%">
          <CalendarHeader
              :calendars="calendars"
              :default-calendar="defaultCalendar"
              :loading="loading"
              :title="$refs.calendar ? $refs.calendar.title : null"
              :type="type"
              :types="types"
              :locale="locale"
              @next="$refs.calendar.next()"
              @prev="$refs.calendar.prev()"
              @reload="reload"
              @refresh="getEventsTrigger(true)"
              @today="focus = ''"
              @typeChange="typeChange"
          />
          <v-sheet class="pa-3 calendar-container">
            <v-calendar
                ref="calendar"
                v-model="focus"
                :event-color="getEventColor"
                :event-overlap-threshold="60"
                :event-ripple="false"
                :events="getEvents()"
                :short-intervals="false"
                :type="type"
                :weekdays="weekday"
                color="primary"
                :locale="locale"
                @change="getEventsTrigger()"
                @click:date="viewDay"
                @click:event="showEvent"
                @click:more="viewDay"
                @mousedown:event="startDrag"
                @mousedown:time="startTime"
                @mousemove:time="mouseMove"
                @mouseup.native="endDrag"
                @mouseleave.native="cancelDrag"
            >
              <template v-slot:event="{ event, timed, eventSummary }">
                <div
                    class="v-event-draggable"
                    v-html="eventSummary()"
                ></div>
                <div
                    v-if="timed"
                    class="v-event-drag-bottom"
                    @mousedown.stop="extendBottom(event)"
                ></div>
              </template>
              <template v-slot:day-body="{ date, week }">
                <div
                    :class="{ first: date === week[0].date }"
                    :style="{ top: nowY }"
                    class="v-current-time"
                ></div>
              </template>
            </v-calendar>
          </v-sheet>
        </div>
      </div>
    </v-skeleton-loader>
    <EventDetails
        :calendars="calendars"
        :create-event="selectedCreate"
        :default-calendar="defaultCalendar"
        :selected-element="selectedElement"
        :selected-event="selectedEvent"
        :selected-open="selectedOpen"
        :locale="locale"
        @close="selectedOpen = false; selectedCreate = false"
        @reload="reload"
    />
  </div>
</template>

<script>
import CalendarHeader from '@/components/Calendar/CalendarHeader';
import CalendarSidebar from '@/components/Calendar/CalendarSidebar';
import EventDetails from '@/components/Calendar/EventDetails';
import axios from 'axios';
import parseEvents from '@/mixin/calendarDataPrepare';

export default {
  name: 'Index',
  mixins: [parseEvents],
  data() {
    return {
      calendars: [],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      loading: false,
      formLoaded: false,
      weekdays: [
        {
          text: 'Sun - Sat',
          value: [0, 1, 2, 3, 4, 5, 6],
        },
        {
          text: 'Mon - Sun',
          value: [1, 2, 3, 4, 5, 6, 0],
        },
        {
          text: 'Mon - Fri',
          value: [1, 2, 3, 4, 5],
        },
        {
          text: 'Mon, Wed, Fri',
          value: [1, 3, 5],
        },
      ],
      types: ['month', 'week', 'day', '4day'],
      type: 'week',
      focus: '',
      defaultCalendar: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      selectedCreate: false,
      events: [],
      locale: localStorage.getItem('langLocal') || 'en',
      edit: false,
      timeout: null,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      ready: false,
    };
  },

  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
    },
  },

  components: {
    CalendarHeader,
    CalendarSidebar,
    EventDetails,
  },

  mounted() {
    this.$refs.calendar.checkChange();
    this.fetchCalendars();
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },

  methods: {
    getEvents() {
      if (this.events.length > 0 && this.calendars.length > 0) {
        return this.events
            .filter((element) => {
              return this.calendars
                  .find((calendarElement) => !element.calendar_id || calendarElement['calendar_calendars.id'] === element.calendar_id && calendarElement.selected);
            });
      } else {
        return [];
      }
    },

    async fetchEvents() {
      this.loading = true;
      try {
        const {data} = await axios.post(`/calendar/events_list`, this.getEventsBodyConstructor(this.focus));
        const unsavedEvent = this.events.find((element) => !element.id);
        this.events = this.parseEvents(data.data);
        if (unsavedEvent) {
          this.events.push(unsavedEvent);
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
        this.formLoaded = true;
      }
    },

    async updateEvent(event) {
      this.loading = true;
      try {
        const {data} = await axios.post(`/calendar/events/${event.id}`, this.parseEventObject(event));
        if (data) {
          this.updateLocalEvent(data.data.base);
        } else {
          await this.fetchEvents();
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    updateLocalEvent(event) {
      const localEvent = Object.assign({}, event);
      this.events.forEach((element) => {
        if (element.id === localEvent.id) {
          element.start = new Date(localEvent.start).getTime();
          element.end = new Date(localEvent.end).getTime();
          element.name = localEvent.name;
          element.timeout = null;
        }
      });
    },

    async fetchCalendars() {
      this.loading = true;
      try {
        this.calendars = this.parseCalendars(
            await axios.post(`/calendar/calendars_list`, {
              form_id: 9,
              column: 'calendar_calendars.id',
            }),
        );
        this.defaultCalendar = this.calendars[0]['calendar_calendars.id'];
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
        this.formLoaded = true;
      }
    },

    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },

    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },

    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    setAsDefault(id) {
      this.defaultCalendar = id;
    },

    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },

    startDrag(element) {
      if (element.event && element.timed) {
        this.dragEvent = element.event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },

    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          name: '',
          color: this.calendars.find((element) => element['calendar_calendars.id'] === this.defaultCalendar)['calendar_calendars.color'],
          start: this.createStart,
          end: this.createStart,
          timed: true,
          timeout: null,
        };

        this.events.push(this.createEvent);
      }
    },

    mouseMove(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },

    roundTime(time, down = true) {
      const roundTo = 15;
      const roundDownTime = roundTo * 60 * 1000;

      return down
             ? time - time % roundDownTime
             : time + (roundDownTime - (time % roundDownTime));
    },

    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
    },

    endDrag(nativeEvent) {
      if (this.extendOriginal && this.createEvent) {
        this.updateEventsTrigger(false, this.createEvent);
      } else if (this.createEvent) {
        this.dropUnsavedEvents();
        this.events.push(this.createEvent);
        this.showEvent({
          nativeEvent: nativeEvent,
          event: this.createEvent,
        }, true);
      } else if (this.dragEvent) {
        this.updateEventsTrigger(false, this.dragEvent);
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },

    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },

    selectCalendars(calendars) {
      this.calendars = calendars;
    },

    getEventsTrigger(force = false) {
      if (force) {
        clearTimeout(this.timeout);
        this.fetchEvents();
        return;
      }
      this.loading = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchEvents();
      }, 1000);
    },

    updateEventsTrigger(force = false, event) {
      if (typeof event.timeout === 'undefined') {
        event.timeout = null;
      }
      if (force) {
        clearTimeout(event.timeout);
        this.updateEvent(event);
        return;
      }
      this.loading = true;
      clearTimeout(event.timeout);
      event.timeout = setTimeout(() => {
        this.updateEvent(event);
      }, 1000);
    },

    reload(action) {
      if (!action || !action.type) {
        this.getEventsTrigger(true);
        return;
      }
      this.updateLocalData(action);
    },

    updateLocalData(action) {
      action.data.start = this.formatDate(action.data.start);
      action.data.end = this.formatDate(action.data.end);
      if (action.type === 'cancelCreate') {
        this.dropUnsavedEvents();
      } else if (action.type === 'create') {
        action.data.color = this.calendars.find((element) => element['calendar_calendars.id'] === action.data.calendar_id)['calendar_calendars.color'];
        const event = Object.assign({}, action.data);
        this.events
            .push({
              ...event,
              start: new Date(event.start).getTime(),
              end: new Date(event.end).getTime(),
              timed: true,
            });
      } else if (action.type === 'createAlt') {
        setTimeout(() => {
          this.events
              .forEach((element) => {
                if (!element.id) {
                  element.start = new Date(action.data.start).getTime();
                  element.end = new Date(action.data.end).getTime();
                  element.name = action.data.name;
                  element.id = action.data.id;
                  element.calendar_id = action.data.calendar_id;
                  element.color = this.calendars.find((element) => element['calendar_calendars.id'] === action.data.calendar_id)['calendar_calendars.color'];
                }
              });
        });
      } else {
        this.events
            .forEach((element, index) => {
              if (element.id === action.data.id) {
                if (action.type === 'update') {
                  this.events[index].start = new Date(action.data.start).getTime();
                  this.events[index].end = new Date(action.data.end).getTime();
                  this.events[index].name = action.data.name;
                } else if (action.type === 'delete') {
                  this.events.splice(index, 1);
                }
              }
            });
      }
    },

    dropUnsavedEvents() {
      const eventsCopy = this.events;
      this.events = eventsCopy.filter((element) => element.id);
    },

    viewDay({date}) {
      this.focus = date;
      this.type = 'day';
    },

    typeChange(type) {
      this.type = type;
    },

    showEvent(
        {
          nativeEvent,
          event,
        },
        createEvent = false,
    ) {

      if (!createEvent && event.id) {
        this.dropUnsavedEvents();
      }

      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.selectedCreate = createEvent;
        setTimeout(() => this.selectedOpen = true, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    getEventColor(event) {
      return event.color ? event.color : this.calendars.find((element) => element['calendar_calendars.id'] === this.defaultCalendar)['calendar_calendars.color'];
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>

@import '../../static/css/calendar';

</style>
